<div class="chart-container">
    <div class="flex mb-2 position-relative">
        <app-report-filters
            [icao]="icao"
            [dataSource]="dataSource"
            [selectedDateFilter]="selectedDateFilter"
            (onChangeIcaoFilter)="changeIcaoFilter($event)"
            (onDateChange)="applyPresetDateFilter($event)"
            (commercialAicraftToogle)="filterChanged($event)"
        >
        </app-report-filters>
        <div class="actions">
            <button [matMenuTriggerFor]="gridActions" mat-icon-button>
                <mat-icon
                    aria-hidden="true"
                    class="mat-icon material-icons"
                    role="img"
                    >settings</mat-icon
                >
            </button>
            <mat-menu
                #gridActions="matMenu"
                class="switch-nested-menu-left-arrows"
                xPosition="before"
            >
                <button (click)="openSettings()" mat-menu-item>Settings</button>
            </mat-menu>
        </div>
    </div>

    <div
        class="table-container mat-elevation-z3"
        (scroll)="onTableScroll($event)"
    >
        <table [dataSource]="dataSource"
               mat-table
               matSort
               matSortActive="dateTime"
               matSortDirection="desc">
            <ng-container matColumnDef="company">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="company"
                                                 propertyName="company">
                            <span>Company</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.company }}
                </td>
            </ng-container>

            <ng-container matColumnDef="tailNumber">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="tailNumber"
                                                 propertyName="tailNumber">
                            <span>Tail #</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    <span *ngIf="isJetNetIntegrationEnabled && info.tailNumber.startsWith('N') && (info.companyId == null || info.companyId == 0)"><a (click)="tailNumberSearch(info.tailNumber)" class="btn-link">{{ info.tailNumber }}</a></span>
                    <span *ngIf="!isJetNetIntegrationEnabled || !info.tailNumber.startsWith('N') || info.companyId > 0"> {{ info.tailNumber }}</span>
                </td>
            </ng-container>

            <ng-container matColumnDef="customerActionStatus">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="customerActionStatus">
                            <span>Customer Action Status</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    <app-customer-action-status  matTooltip="{{ info.toolTipText }}" matTooltipPosition="after" customerActionStatusEmailRequired="{{info.customerActionStatusEmailRequired}}" customerActionStatusSetupRequired="{{info.customerActionStatusSetupRequired}}" customerActionStatusTopCustomer="{{info.customerActionStatusTopCustomer}}"></app-customer-action-status>
                </td>
            </ng-container>

            <ng-container matColumnDef="flightNumber">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="flightNumber"
                                                 propertyName="flightNumber">
                            <span>Flight #</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.flightNumber }}
                </td>
            </ng-container>

            <ng-container matColumnDef="originated">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="originated"
                                                 propertyName="originated">
                            <span>Origin ICAO</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.originated }}
                </td>
            </ng-container>

            <ng-container matColumnDef="hexCode">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="hexCode"
                                                 propertyName="hexCode">
                            <span>Hex #</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.hexCode }}
                </td>
            </ng-container>

            <ng-container matColumnDef="aircraftType">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="aircraftType"
                                                 propertyName="aircraftType">
                            <span>Aircraft</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.aircraftType }}
                </td>
            </ng-container>

            <ng-container matColumnDef="dateTime">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="dateTime"
                                                 propertyName="dateTime"
                                                 [columnFormat]="4">
                            <span>Date and Time</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{
                        info.flightNumber || !info.percentOfVisits
                            ? (getlocalDateTime(info.dateTime) | date : "MM/dd/YYYY HH:mm")
                            : ""
                    }}
                </td>
            </ng-container>

            <ng-container matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="status"
                                                 propertyName="status">
                            <span>Departure / Arrival</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.status }}
                </td>
            </ng-container>

            <ng-container matColumnDef="pastVisits">
                <th *matHeaderCellDef mat-header-cell>
                    <span>Past Visits</span>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="pastVisits"
                                                 propertyName="pastVisits"
                                                 [columnFormat]="1">
                            <span>Departure / Arrival</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.pastVisits }}
                </td>
            </ng-container>

            <ng-container matColumnDef="visitsToMyFbo">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="visitsToMyFbo"
                                                 propertyName="visitsToMyFbo"
                                                 [columnFormat]="1">
                            <span>Visits to {{ fboName }}</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.visitsToMyFbo }}
                </td>
            </ng-container>

            <ng-container matColumnDef="isConfirmedVisit">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="isConfirmedVisit"
                                                 propertyName="isConfirmedVisit"
                                                 [columnFormat]="8">
                            <span>Visit to {{ fboName }}?</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell clickStopPropagation>
                    <mat-slide-toggle *ngIf="info.status == 'Arrival'"
                                      color="primary"
                                      [(ngModel)]="info.isParkedWithinGeofence"
                                      (change)="confirmedVisitToggled(info)">
                    </mat-slide-toggle>
                </td>
            </ng-container>

            <ng-container matColumnDef="percentOfVisits">
                <th *matHeaderCellDef mat-header-cell>
                    <span *ngIf="dataSource">
                        <app-table-column-filter [matDataSource]="dataSource"
                                                 [matSort]="sort"
                                                 columnId="percentOfVisits"
                                                 propertyName="percentOfVisits"
                                                 [columnFormat]="1">
                            <span>Percent of Visits</span>
                        </app-table-column-filter>
                    </span>
                </th>
                <td *matCellDef="let info" mat-cell>
                    {{ info.percentOfVisits | percent }}
                </td>
            </ng-container>

            <tr *matHeaderRowDef="visibleColumns; sticky: true"
                mat-header-row></tr>
            <tr *matRowDef="let row; columns: visibleColumns"
                mat-row class="example-element-row"
                (click)="onClickAircraft(row)"></tr>
        </table>

        <ngx-ui-loader
            [loaderId]="chartName"
            bgsColor="rgb(100, 181, 246)"
            bgsPosition="center-center"
            bgsType="ball-spin"
            overlayColor="rgba(0, 0, 0, 0.1)"
        ></ngx-ui-loader>
        <div class="invisible">
            <mat-paginator
                [pageSizeOptions]="[10, 25, 50, 100]"
                showFirstLastButtons
            ></mat-paginator>
        </div>
    </div>
</div>
