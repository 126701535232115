
        <mat-table [dataSource]="groupDistributionDataSource">
            <ng-container matColumnDef="select">
                <th *matHeaderCellDef mat-header-cell>
                    <input (change)="selectAction()"
                           [(ngModel)]="selectAll"
                           [indeterminate]="isIndeterminate()"
                           class="checkboxHeight"
                           id="selectAllCheckbox"
                           type="checkbox" />
                </th>
                <td *matCellDef="let customer" mat-cell>
                    <input type="checkbox" [(ngModel)]="customer.checked" (change)="customerSelected();" />
                </td>
            </ng-container>

            <ng-container matColumnDef="customer">
                <th *matHeaderCellDef mat-header-cell>
                    <input placeholder="Customer Filter"
                           class="e-input"
                           id="searchBox" (input)="applyFilter($event.currentTarget.value)" />
                </th>
                <td *matCellDef="let customer" mat-cell (click)="customerSelectedRow(customer);">
                    <div style="width:250px">{{customer.company}}</div>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="getTableColumns(); sticky: true" mat-header-row></tr>
            <tr *matRowDef="let row; columns: getTableColumns()" mat-row></tr>
        </mat-table>
