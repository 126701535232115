<h1 mat-dialog-title>FBO Group Customer Pricing Report</h1>
<div class="subtitle">Select the customer(s) you'd like to generate a price sheet for.</div>

<div mat-dialog-content class="dialogContent">
        <app-group-analytics-generate-dialog-grid [groupDistributionGridData]="dataSources" (customerSelectedClicked)="customerSelectedClicked($event)" (selectAllClicked)="selectAllClicked($event)"></app-group-analytics-generate-dialog-grid>
</div>
<div class="" mat-dialog-actions>
    <button
        (click)="onGenerate()"
        [disabled]="
            !selectedCustomers ||
            !selectedCustomers.length ||
            downloading ||
            emailing
        "
        color="accent"
        mat-raised-button
    >
        {{ !downloading ? "Generate and Download" : "" }}
        <mat-spinner *ngIf="downloading" diameter="20"></mat-spinner>
    </button>
    <button
        (click)="onEmail()"
        [disabled]="
            !selectedCustomers ||
            !selectedCustomers.length ||
            emailing ||
            emailing
        "
        color="green"
        mat-raised-button
    >
        {{ !emailing ? "Email Pricing" : "" }}
        <mat-spinner *ngIf="emailing" diameter="20"></mat-spinner>
    </button>
</div>
